
.feedbackScreen{
    flex: 100%;
    margin-top: 100px;
    padding-bottom: 50px;
}

.feedbackContent{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    padding-left: 120px;
    padding-right: 120px;
    box-sizing: border-box;
    
    gap: 20px;
}

.textInputFeedback
{
    width: 50%;
}

.faq{
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 20px;
    margin-top: 40px;
    border-bottom: 1px solid #aaa;
}

.faqTitleRow{
    width: 100%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.faqContent{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

@media (max-width:900px) {

    .feedbackContent{
        padding: 20px;
    }

    .textInputFeedback
    {
        width: 100%;
    }

    .faq{
        width: 100%;
    }
}