.roundButton{
    display: flex;
    width: 97.692px;
    height: 97.692px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: #D73907;
    box-shadow: 0px 2.397658348083496px 11.988290786743164px 0px rgba(0, 0, 0, 0.12), 0px 4.795316696166992px 4.795316696166992px 0px rgba(0, 0, 0, 0.14), 0px 7.19297456741333px 2.397658348083496px -4.795316696166992px rgba(0, 0, 0, 0.20);
    cursor: pointer;
}

.roundButton.breakingBarriers{
    height: 90px;
    width: 90px;
    margin-top: 20px;
}

.roundButton:hover{
    background-color: #B83106;
}

.roundButton:active{
    background-color: #992905;
}

.roundButton.bottomLeft{
    width: 64px;
    height: 64px;
    position: absolute;
    bottom: -40px;
    left: -32px;
}

.roundButton.audio{
    position: absolute;
    top: 10px;
    bottom: 10px;
    left: 10px;
    right: 10px;
    height: auto;
    width: auto;
}




h1, h2{
    margin: 20px;
}

#curiousButton:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.redArrow{
    color: #D73907;
}

.h1



.accountBackButton{
    display: flex;
    padding: 6px 16px;
    align-items: center;
    gap: 8px;
    border: white solid 1.5px;
    border-radius: 24px;
    cursor: pointer;
}


@media (max-width:1100px) {

    .roundButton.bottomRight{
       right: -16px; 
    }
    .roundButton.bottomLeft{
        left: -16px; 
     }
}


@media (max-width:1024px) {

    .redArrow{
        transform: rotate(90deg);
    }

}



.tooltip {
    position: relative;
    display: inline-block;
    
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    min-width: 159px;
    width: fit-content;
    /* width: 250px; */
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px 12px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -60px;
    
    /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
    opacity: 0;
    transition: opacity 1s;
  }

  .tooltip .tooltiptext.side {
    position: absolute;
    z-index: 1;
    bottom: -150%;
    left: 500%;
    margin-left: -60px;
    
    /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
    opacity: 0;
    transition: opacity 1s;
  }
  
  
  .tooltip .tooltiptext.button {
    bottom: 120%;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }


  .tooltipIcon{
      height: 16px;
      width: 16px;
  }
