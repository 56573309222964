body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  margin: 0 0 0 0;
}

h2 {
  margin: 0 0 0 0;
}
h3 {
  margin: 0 0 0 0;
}
h4 {
  margin: 0 0 0 0;
}
p {
  margin: 0 0 0 0;
}
@font-face {
  font-family: "NotoSansKR";
  src: local("NotoSansKRThin"),
    url("./fonts/Noto_Sans_KR/NotoSansKR-Thin.otf") format("truetype");
    font-weight: 100;
    -webkit-font-smoothing: antialiased;
  }
  

@font-face {
  font-family: "NotoSansKR";
  src: local("NotoSansKRLight"),
    url("./fonts/Noto_Sans_KR/NotoSansKR-Light.otf") format("truetype");
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
  }
  

@font-face {
  font-family: "NotoSansKR";
  src: local("NotoSansKRRegular"),
   url("./fonts/Noto_Sans_KR/NotoSansKR-Regular.otf") format("truetype");
   font-weight: 400;
   -webkit-font-smoothing: antialiased;
  }
  
    
@font-face {
  font-family: "NotoSansKR";
  src: local("NotoSansKRMedium"),
   url("./fonts/Noto_Sans_KR/NotoSansKR-Medium.otf") format("truetype");
   font-weight: 500;
   -webkit-font-smoothing: antialiased;
  }
  


@font-face {
  font-family: "NotoSansKR";
  src: local("NotoSansKRBold"),
   url("./fonts/Noto_Sans_KR/NotoSansKR-Bold.otf") format("truetype");
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  }

  
@font-face {
  font-family: "NotoSansKR";
  src: local("NotoSansKRBlack"),
   url("./fonts/Noto_Sans_KR/NotoSansKR-Black.otf") format("truetype");
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  }




  

@font-face {
  font-family: "RedHatText";
  src: local("RedHatTextRegular"),
   url("./fonts/RedHatText/RedHatText-Regular.ttf") format("truetype");
   font-weight: 400;
   -webkit-font-smoothing: antialiased;
  }
  
    
@font-face {
  font-family: "RedHatText";
  src: local("RedHatTextMedium"),
   url("./fonts/RedHatText/RedHatText-Medium.ttf") format("truetype");
   font-weight: 500;
   -webkit-font-smoothing: antialiased;
  }

  
@font-face {
  font-family: "RedHatText";
  src: local("RedHatTextSemiBold"),
    url("./fonts/RedHatText/RedHatText-SemiBold.ttf") format("truetype");
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
  }
  


@font-face {
  font-family: "RedHatText";
  src: local("RedHatTextBold"),
   url("./fonts/RedHatText/RedHatText-Bold.ttf") format("truetype");
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  }



  
@font-face {
  font-family: "Amita";
  src: local("Amita"),
   url("./fonts/Amita/Amita-Regular.ttf") format("truetype");
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  }



/* ---------------------------------------- */
               /* Khula */
/* ---------------------------------------- */


@font-face {
  font-family: "Khula";
  src: local("KhulaLight"),
   url("./fonts/Khula/Khula-Light.ttf") format("truetype");
   font-weight: 300;
   -webkit-font-smoothing: antialiased;
  }


@font-face {
  font-family: "Khula";
  src: local("KhulaRegular"),
   url("./fonts/Khula/Khula-Regular.ttf") format("truetype");
   font-weight: 400;
   -webkit-font-smoothing: antialiased;
  }
  
    

  
@font-face {
  font-family: "Khula";
  src: local("KhulaSemiBold"),
    url("./fonts/Khula/Khula-SemiBold.ttf") format("truetype");
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
  }
  


@font-face {
  font-family: "Khula";
  src: local("KhulaBold"),
   url("./fonts/Khula/Khula-Bold.ttf") format("truetype");
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  }


@font-face {
  font-family: "Khula";
  src: local("KhulaExtraBold"),
   url("./fonts/Khula/Khula-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  -webkit-font-smoothing: antialiased;
  }
